<template>
  <div>
    <v-card
      tile
      outlined
      class="titleCard px-5 py-2 rounded-lg"
    >
      <!-- #region Дата оформления -->
      <info-chip
        class="on-border-right pr-0"
        title="Дата оформления"
        :value="createDate"
      >
      </info-chip>

      <v-chip class="on-border-left text-h5 px-5 py-5" :color="statusColor">
        {{ statusText }}
      </v-chip>
      <!-- #endregion -->
      <!-- #region Инфа о полете -->
      <v-row class="pa-2 mt-1">
        <v-col md="10" cols="12">
          <v-row align="center" justify="space-between" class="px-3">
            <!-- #region картинка и авиакомпания -->
            <v-col md="2" cols="12" align-self="center" justify-center>
              <v-img
                :src="item.flights[0].carrier.imgUrl"
                :max-height="$vuetify.breakpoint.mobile ? '90' : '100'"
                contain
              ></v-img>
              <div class="text-h5 text-center indigo--text">
                {{ item.flights[0].carrier.name }}
              </div>
            </v-col>
            <!-- #endregion -->
            <!-- #region информация о полете -->
            <v-col md="10" cols="12">
              <flight-info :data="item.flights"></flight-info>
            </v-col>
            <!-- #endregion -->
          </v-row>
          <!-- #endregion -->
          <!-- #region Divider Пассажир -->
          <v-row justify="center" class="divider mt-1">
            <v-divider class="dashed-divider"></v-divider>
            <span>Пассажиры</span>
            <v-divider class="dashed-divider"></v-divider>
          </v-row>
          <!-- #endregion -->
          <!-- #region Информация пассажиров -->
          <v-row>
            <v-col
              md="4"
              cols="12"
              v-for="(pass, i) in item.passengers"
              :key="i"
            >
              <ticket-passengers-info
                :item="pass"
                :ticket="item.tickets"
                :contactInfo="item.contactInfo"
              ></ticket-passengers-info>
            </v-col>
          </v-row>
          <!-- #endregion -->
        </v-col>
        <!-- #region Кнопки -->

        <v-col md="2" cols="12">
          <v-row align-content="start" class="btn-group" justify="center">
            <v-btn
              v-if="item.status === $enums.TicketStatusCodes.Booked"
              width="90%"
              class="mt-2 move"
              color="orange"
              elevation="0"
              :loading="buing"
              @click="onBuy"
            >
              Купить
            </v-btn>
            <v-btn
              v-if="item.status === $enums.TicketStatusCodes.Booked"
              width="90%"
              class="mt-2 move"
              color="red"
              elevation="0"
              @click="cancelBook"
              :loading="canceling"
            >
              Отмена брони
            </v-btn>
            <v-col cols="12" class="text-h5 d-inline-block font-weight-bold orange--text text-center">
              {{ item.cost.total.toString() }} РУБ
            </v-col>
          </v-row>
        </v-col>
        <!-- #endregion -->
      </v-row>
    </v-card>
  </div>
</template>

<script>
import ApiCalls from "@/mixins/booking/ApiCalls";
import DateFormat from "@/mixins/DateFormat";
import InfoChip from "@/components/UI/InfoChip.vue";
import TicketPassengersInfo from "@/components/BookedTicket/TicketPassengerInfo.vue";
import FlightInfo from "@/components/BookedTicket/FlightInfo.vue";
import ClipboardData from "@/mixins/ClipboardData.js";
import { format } from "date-fns";

export default {
  name: "BookedTicketItem",
  components: { InfoChip, TicketPassengersInfo, FlightInfo },
  mixins: [DateFormat, ApiCalls, ClipboardData],
  props: {
    item: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      canceling: false,
      buing: false,
      paymentUrl: "",
    };
  },
  computed: {
    createDate() {
      return format(new Date(this.item.createdAt), "HH:mm dd.MM.yyyy");
    },
    statusText() {
      switch (this.item.status) {
        case 2:
          return "Booked";
        case 3:
          return "Bought";
        case 4:
          return "Book Сanceled";
        case 5:
          return "Void";
        case 6:
          return "Returned";
        case 7:
          return "Not Changed";
        case 8:
          return "Void (re-voiding required)";
        default:
          return "Unknown";
      }
    },
    statusColor() {
      let color;
      switch (this.item.status) {
        case 2:
          color = "orange";
          break;
        case 8:
          color = "orange";
          break;
        case 3:
          color = "green";
          break;
        default:
          color = "red";
          break;
      }
      return color;
    },
  },
  methods: {
    async onBuy() {
      this.buing = true;
      try {
        let req = {
            OrderNumber: this.item.workId,
            Amount: this.item.cost.total,
            // dev
            SuccessUrl: `https://bpo.travel/show-receipt?orderNumber=${this.item.workId}`,
            FailUrl: `https://bpo.travel/show-receipt?orderNumber=${this.item.workId}${this.paymentUrl}`,
            // RetUrl: `http://localhost:8080/show-receipt?orderNumber=${this.value.workId}`,
            // FailUrl: `http://localhost:8080/show-receipt?orderNumber=${this.value.workId}${this.paymentUrl}`,
            Email: this.email,
            Phone: this.phone,

        };
        await this.$axios.post(`/Payment/Acquiring/Create`, req).then(response => {
          const data = response.data;
          this.paymentUrl = data.url
        })
      } finally {
        this.buing = false;
      }
      // Same tab
      await window.open(this.paymentUrl, "_self");
    },
    async cancelBook() {
      this.canceling = true;
      await this.$axios.post(
        this.$enums.Endpoints.Order.Cancel + `?data=${this.item.workId}`
      );
      this.item.status = 4;
      this.canceling = false;
    },
  }
};
</script>

<style scoped>
.titleCard {
  border: solid 2px orange !important;
}

.on-border-right {
  position: absolute;
  top: -15px;
  right: 20px;
}

.on-border-left {
  position: absolute;
  top: -20px;
  left: 20px;
}

.divider {
  font-size: 20px;
}

.dashed-divider {
  margin: auto 10px;
  border-top: 2px dashed rgb(114, 114, 114);
}

.btn-group,
.not-ticket {
  padding: 10px 0;
  color: #12121244;
}

.not-ticket span {
  font-size: 50px;
  color: orange;
  min-height: 100%;
}
</style>
