<template>
  <v-chip class="pr-0" color="orange" @click="CopyToBuffer(value)">
    <span>{{ title }} </span>
    <v-chip color="green" class="ml-2 chip-value">
      {{ value }}
    </v-chip>
  </v-chip>
</template>

<script>
import ClipboardData from "@/mixins/ClipboardData";

export default {
  name: "InfoChip",
  mixins: [ClipboardData],
  props: {
    title: {
      type: String,
      require: true,
    },
    value: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      isCopiedToBuffer: false,
      bufferText: "",
    };
  },
};
</script>

<style></style>
