<template>
    <v-container>
        <h2 v-if="item.status === $enums.TicketStatusCodes.BookСanceled">Бронь отменена</h2>
        <h2 v-if="item.status === $enums.TicketStatusCodes.Bought">Бронь оплачена</h2>
        <h2 
          v-if="item.status === $enums.TicketStatusCodes.VoidBooked || 
          item.status === $enums.TicketStatusCodes.Void"
          >
          Анулирование
        </h2>
        <booked-ticket-item v-if="itemLoaded && item.status === $enums.TicketStatusCodes.Booked"
            :item="item"
            class="mt-2"
          ></booked-ticket-item>
    </v-container>
</template>

<script>
import BookedTicketItem from "@/components/BookedTicket/BookedTicketItem.vue";
import ApiCalls from "@/mixins/booking/ApiCalls";

export default {
  name: "BookedTicket",
  mixins: [ApiCalls],
  components: {
     BookedTicketItem,
  },
  props: [
    "id",
  ],
  data() {
    return {
        item: null,
        itemLoaded: false,
    };
  },
  async mounted() {
    setTimeout(this.loadOrder, 500);

  },
  methods: {
    async loadOrder() {
      try {
        let resp = await this.$axios.get(`/Data/Order/Get/${this.id}`);
        this.item = resp.data;
        this.itemLoaded = true;
      } 
      catch (e) {
        console.warn(e.response)
      }
    }
  },
};
</script>