<template>
  <v-card>
    <v-row>
      <v-col cols="9" class="py-0">
        <v-card-title class="text-h5" @click="CopyToBuffer(item.surname)">
          {{ fullNameShortened }}
        </v-card-title>
        <v-card-subtitle
          v-if="item.ticket !== null"
          class="text-h8"
          @click="CopyToBuffer(ticketAfterCheck[0].number)"
        >
          {{ ticketAfterCheck[0].number }}
        </v-card-subtitle>
      </v-col>
      <v-col cols="3" align-self="center">
        <v-btn icon @click="show = !show">
          <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-card-actions>
      <v-spacer></v-spacer>
    </v-card-actions>

    <v-expand-transition>
      <v-card-text v-show="show" class="py-0 pb-1">
        <div
          @click="
            CopyToBuffer(`${item.surname} ${item.firstName} ${item.middleName}`)
          "
        >
          ФИО: {{ fullName }}
        </div>
        <div @click="CopyToBuffer(formatedDate(item.birthday))">
          Дата рождения: {{ formatedDate(item.birthday) }}
        </div>
        <v-divider></v-divider>


        <v-divider></v-divider>
        <div @click="CopyToBuffer(item.identityDoc.typeCode)">
          Тип документа:
          {{ document }}
        </div>
        <div @click="CopyToBuffer(item.identityDoc.docNumber)">
          Номер документа: {{ item.identityDoc.docNumber }}
        </div>
        <div @click="CopyToBuffer(item.identityDoc.issueCountryCode)">
          Страна документа: {{ item.identityDoc.issueCountryCode }}
        </div>
        <div @click="CopyToBuffer(formatedDate(item.identityDoc.issueDate))">
          Дата выдачи:
          {{ formatedDate(item.identityDoc.issueDate) }}
        </div>
        <div
          @click="
            CopyToBuffer(
              item.identityDoc.expiryDate !== null
                ? formatedDate(item.identityDoc.expiryDate)
                : 'не указан'
            )
          "
        >
          Срок действия:
          {{
            item.identityDoc.expiryDate !== null
              ? formatedDate(item.identityDoc.expiryDate)
              : "не указан"
          }}
        </div>

        <v-divider></v-divider>

        <div @click="CopyToBuffer(contactInfo.emailAddress)">
          Эл. почта: {{ contactInfo.emailAddress }}
        </div>
        <div @click="CopyToBuffer(contactInfo.phone)">
          Телефон: {{ contactInfo.phone }}
        </div>
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { format } from "date-fns";
import DateFormat from "@/mixins/DateFormat";
import ClipboardData from "@/mixins/ClipboardData";

export default {
  name: "TicketPassengerInfo",
  mixins: [DateFormat, ClipboardData],
  props: {
    ticket: {
      type: Array,
      required: true,
    },
    contactInfo: {
      type: Object,
    },
    item: {
      type: Object,
      require: true,
    },
    colorIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    formatedDate(date) {
      return format(new Date(date), "dd.MM.yyyy");
    },
    formatedDateHM(date) {
      return date != "" ? format(new Date(date), "HH.mm dd.MM.yyyy") : "";
    },
  },
  computed: {
    ticketAfterCheck() {
      return this.ticket.length > 0
        ? this.ticket
        : [{ number: "", date: "", price: "" }];
    },
    document(){
      switch (this.item.identityDoc.typeCode){
        case "PS": return "Паспорт РФ";
        case "SR": return "Свидетельство о рождении";
        case "PSP": return "Заграничный паспорт РФ";
        case "NP": return "Национальный паспорт";
        case "CVV": return "Свидетельство на возвращение в страны СНГ";
        default: return "Документ";
      }
    },
    fullName() {
      return `${this.item.surname} ${this.item.firstName} ${this.item.middleName}`;
    },
    fullNameShortened() {
      return `${this.item.surname} ${this.item.firstName.substr(
        0,
        1
      )}.${this.item.middleName.substr(0, 1)}.`;
    },
  },
};
</script>
