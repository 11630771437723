<template>
  <v-card class="pt-1">
    <v-card-text>
      <segment :data="flights[0]"></segment>
    </v-card-text>
    <v-expand-transition>
      <div v-show="extended">
        <segment
          v-for="(item, i) in secondaryArray"
          :key="i"
          :data="item"
        ></segment>
      </div>
    </v-expand-transition>
    <v-card-actions v-if="data.length > 1" class="justify-center">
      <v-btn
        justify-content="center"
        color="orange"
        elevation="0"
        @click="extended = !extended"
      >
        {{ extended ? "Свернуть" : "Развернуть" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import DateFormat from "@/mixins/DateFormat";
import Segment from "@/components/BookedTicket/Segment.vue";
export default {
  components: { Segment },
  name: "FlightInfo",
  mixins: [DateFormat],
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      extended: false,
      secondaryArray: [],
    };
  },
  mounted() {
    this.secondaryArray = this.data.slice(1);
  },
  methods: {
    sortedFlights() {
      return this.data.sort(function (a, b) {
        if (new Date(a.date) < new Date(b.date)) {
          return 1;
        }
        if (new Date(a.date) > new Date(b.date)) {
          return -1;
        }
        // a должно быть равным b
        return 0;
      });
    },
  },
  computed: {
    flights() {
      return this.extended ? this.sortedFlights() : [this.sortedFlights()[0]];
    },
  },
};
</script>

<style></style>
