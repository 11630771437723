import store from "@/services/store";

export default {
    data() {
        return {
        isCopiedToBuffer: false,
        bufferText:"",
        }
    }, methods: {
        CopyToBuffer(str) {
            const el = document.createElement("textarea");
            el.value = str;
            el.setAttribute("readonly", "");
            el.style.position = "absolute";
            el.style.left = "-9999px";
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
            this.bufferText = str;
            this.isCopiedToBuffer = true;
            store.state.snackbar = {
                show: true,
                message: `Скопировано - ${this.bufferText}`,
                color: "orange",
                timeout: 1000,
            }
        },
    }
}